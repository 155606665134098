<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheNavBar',
  data() {
    return {
      breadcrumbs: this.$route.meta.breadcrumbs,
      collapsed: null,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
        .then(() => this.$router.push('/'));
    },
  },
  watch: {
    '$route.meta.breadcrumbs': function watchBreadcrumbs() {
      this.breadcrumbs = this.$route.meta.breadcrumbs;
    },
  },
  computed: {
    // Alias the access level so it's responsive
    ...mapGetters({
      accessLevel: 'user/accessLevel',
      oemCount: 'admin/pendingUserCount',
      offline: 'ui/offline',
      notifications: 'user/notifications',
    }),
    plain() {
      return this.offline || !this.$route.matched.some(route => route.meta.navbar);
    },
    oem() {
      return !this.plain && this.accessLevel === 'OEM';
    },
    notificationCount() {
      return this.notifications ? this.notifications.length : 0;
    },
    staging() {
      return !!process.env.VUE_APP_STAGING;
    },
  },
};
</script>

<template>
  <b-navbar toggleable="md" type=dark variant="primary" class='mb-2'>
    <b-navbar-toggle v-if='!plain' target="nav-collapse"></b-navbar-toggle>
      <b-navbar-brand to='/loggers'>
        PMGateway <span v-if='staging'>(STAGING)</span>
      </b-navbar-brand>

      <!-- Everything below here is hidden on smaller screens -->
      <b-collapse v-if='!plain' id="nav-collapse" is-nav>
        <!-- Main Navigation Links -->
        <b-navbar-nav>
          <b-nav-item>
            <b-breadcrumb class='bg-primary mh-100'>
              <b-breadcrumb-item v-for='bc in $route.meta.breadcrumbs' :key='bc.text'>
                <b-link :to='bc.to' class='text-light'>{{ bc.text }}</b-link>
              </b-breadcrumb-item>
            </b-breadcrumb>
        </b-nav-item>
          </b-navbar-nav>

        <!-- RH Icons -->
        <b-navbar-nav v-if='!plain' class='ml-auto'>
          <b-nav-item v-if='oem' to="/oem" v-b-popover.hover.bottom="'OEM Panel'" right>
            <fa-icon icon='wrench' ></fa-icon><span class='d-md-none ml-4'>OEM Panel</span>
            <b-badge v-if='oemCount && oemCount > 0' variant='light' class='ml-1'>
              {{ oemCount }}
            </b-badge>
          </b-nav-item>
          <b-nav-item v-b-popover.hover.bottom="'Notifications'"
                      @click="$bvModal.show('notification-modal')"
                      right>
            <fa-icon icon='bell' /><span class='d-md-none ml-4'>Notifications</span>
            <b-badge v-if='notificationCount > 0' variant='light' class='ml-1'>
              {{ notificationCount }}
            </b-badge>
          </b-nav-item>
          <b-nav-item v-b-popover.hover.bottom="'Leave Feedback'" to='/feedback' right>
            <fa-icon icon='comment-dots' /><span class='d-md-none ml-4'>Feedback</span>
          </b-nav-item>
          <b-nav-item v-b-popover.hover.bottom="'Help'" to='/help' right>
            <fa-icon icon='question-circle' /><span class='d-md-none ml-4'>Help</span>
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-if='!plain'>
          <!-- User Dropdown -->
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template slot="button-content">
              <em> {{ this.$store.getters['user/name'] }}</em>
            </template>
            <b-dropdown-item disabled>
              {{ this.$store.getters['user/email'] }}
            </b-dropdown-item>
            <b-dropdown-item disabled>
              {{ this.$store.getters['user/organisation'] }}
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item to='/profile'>Profile</b-dropdown-item>
            <b-dropdown-item @click='logout' >Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<style lang='scss' scoped>
  .breadcrumb {
      padding: 0 !important;
      margin: 0 !important;
  }
  .nav-item {
    display: inline;
    float: none;
  }
</style>
